import React from 'react'
import style from "./SubscriptionDetails.module.css"
import subscriptionImage from "../../assests/subscriptionMail.png"
import downloadIcon from "../../assests/DownloadIcon.png"


const SubscriptionDetails = () => {

  const UserName=localStorage.getItem("username")
  const key = JSON.parse(localStorage.getItem("access-token"));
  const UserID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  return (
    <div className={style.container}>
        <div className={style.subscription}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>Hello {UserName}!</p>
            <span>Take a look at your subscription plan.</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={subscriptionImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>
        <div className={style.planSection}>
        <div className={style.planHeader}>
            <p>Subscription Plan</p>
        </div>
        <div className={style.subscriptionPlan}>
        <div className={style.subscriptionPlanTop}>
            <p>Renew Subscription </p>
        </div>
         <div className={style.subscriptionPlanBottom}>
            <h4> GrammarLocker  699 INR/Year</h4>
            <p>Active</p>
            <p>Active until May 7th, 2025.</p>
        </div>
        </div>
        </div>
        <div className={style.planSection}>
        <div className={style.planHeader}>
            <p> Billing Details</p>
        </div>
        <div className={style.subscriptionPlan}>
        <div className={style.subscriptionPlanTop}>
            <p>Change billing information </p>
        </div>
         <div className={style.subscriptionPlanBottom}>
            <h4> John Doe</h4>
            <p>John123@gmail.com</p>
        </div>
        <div className={style.subscriptionDetail}>
            <table className={style.detailTable}>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>GrammarLocker</td>
                        <td>INV-635-633-
                        6353593738</td>
                        <td>08/05/2024</td>
                        <td>699.00 INR</td>
                        <td>Active</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className={style.downloadSection}>
        <div className={style.downloadCard}>
            <p>Invoice</p>
            <img src={downloadIcon} alt='invoice'/>
        </div>
        <div className={style.downloadCard}>
            <p>Receipt</p>
            <img src={downloadIcon} alt='receipt'/>
        </div>
        </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default SubscriptionDetails