import React, { useState, useEffect } from "react";
import style from "./Category.module.css";
import { BiSearch } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toHaveFormValues } from "@testing-library/jest-dom/matchers";
import { FiPower } from "react-icons/fi";
import Loader from "../Loder/Loader";
import {baseurl} from '../Url'


const Category = () => {
  const [popup, setPopup] = useState(false);
  const [category, setCategory] = useState(false);
  const [subCategory, setSubCategory] = useState(false);
  const [details, setDetails] = useState([]);
  const [subDetails, setSubDetails] = useState([]);
  const [goal, setGoal] = useState(false);
  const [goalDetail, setGoalDetail] = useState([]);
  const [topic, setTopic] = useState(false);
  const [topicDetail, setTopicDetail] = useState([]);
  const [goalSelection, setGoalSelection] = useState("");
  const [timeline, setTimeline] = useState("");
  const [comment, setComment] = useState("");
  const [profilePopup, setProfilePopup] = useState(false);
  const [aboutExp, setAboutExp] = useState("");
  const [yearExp, setYearExp] = useState("");
  const[search,setSearch]=useState("")
  const [sessionExpire, setSessionExpire] = useState(false);
  const [currentPath, setCurrentPath] = useState("Category");
  const[loading,setLoading]=useState(true)
  const[subData,setSubData]=useState(false)


  const navigate=useNavigate()
  const key = JSON.parse(localStorage.getItem("access-token"));
  const lastSublevelId = localStorage.getItem("SubLevelId");
  const FirstId = localStorage.getItem("MainLevelId");
  const ClubID=localStorage.getItem("ClubID")
  const OrgID=localStorage.getItem("ORGID")

 const clubIdsArray = ClubID.split(',');

  async function LearningsGet() {
    await fetch(`${baseurl}/main/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        type: "web",
        club_id:clubIdsArray
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false)
        if(data.description === "profile_failed"){
          setProfilePopup(true)
        }
        else{
          setDetails(data.data);
          setCategory(true);
        }
        
      });
  }
  async function subLearning(learning_id) {
    await fetch(`${baseurl}/sub/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: OrgID,
        id: learning_id,
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false)
        if(data.category===true){
          setSubDetails(data.data);
        }
        else{
          setSubData(true)
          setPopup(true);
          setGoal(true)
          myGoal()
        }

       
        console.log(data);
      });
  }
  async function myGoal() {
    await fetch(`${baseurl}/goal/list`, {
      method: "POST",
      body: JSON.stringify({
        source: "web",
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setGoalDetail(data.data);

        console.log(data);
      });
  }
  async function Topic(e) {
    e.preventDefault()
    console.log("topic");
    await fetch(`${baseurl}/topic/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        category_id:subData===true?FirstId:lastSublevelId,
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTopicDetail(data.data);
        console.log(data);
      });
  }
  async function SaveLearning() {
    await fetch(`${baseurl}/userlearning/saving`, {
      method: "POST",
      body: JSON.stringify(saveData),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
       
        if (data.status === true) {
          localStorage.setItem("userlerningid", data.data.userlearnings_id);
          setTopic(false);
          setPopup(false)
         navigate("/learnings")
         localStorage.removeItem("MainLevelId");
         localStorage.removeItem("SubLevelId");
        }
      });
  }
  
  async function learningValidation(e) {
    setProfilePopup(false);
    e.preventDefault();
    await fetch(
      `${baseurl}/profile/edit/learning/validation`,
      {
        method: "POST",
        body: JSON.stringify({
          year_of_exp: yearExp,
          about_exp: aboutExp,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          LearningsGet();
        }
        console.log(data);
      });
  }

  const saveData = {
    Organization_ID: OrgID,
    Goal: [
      {
        goal: goalSelection,
        time_line: timeline,
        comments: comment,
      },
    ],
    Topics: topicDetail,
    Categories: [
      {
        first_level: FirstId,
        last_level:subData===true?FirstId:lastSublevelId,
      },
    ],
  };

  async function SearchApi() {
    await fetch(`${baseurl}/main/category/search`, {
      method: "POST",
      body: JSON.stringify({
        search:search,
        org_id: OrgID,
        club_id:clubIdsArray
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
          setDetails(data.data);
      });
  }

  const handleCardClick = (learning) => {
    const newPath = `${currentPath}/${learning}`;
    setCurrentPath(newPath);
  };
  // useEffect(()=>{
  //   console.log(currentPath);
  // },[currentPath])

  useEffect(()=>{
    if(search.length>0){
      SearchApi()
    }
    else{
      LearningsGet()
    }
  },[search])
  useEffect(() => {
    LearningsGet();
  }, [key]);
  useEffect(() => {
    console.log(lastSublevelId);
  }, [lastSublevelId]);
  return (
    <div className={style.Container}>
      {loading?<Loader/>:''}
      {profilePopup ? (
        <>
          <div
            className={style.ProfileOverlay}
            onClick={() => {
              setProfilePopup(false);
              navigate("/learnings")
            }}
          ></div>
          <div className={style.AddressSection}>
            <form action="" onSubmit={learningValidation}>
              <div className={style.Formtext}>
                <p>Please enter experience details</p>
              </div>
              <input
                type="text"
                name=""
                id=""
                value={yearExp}
                required
                onChange={(e) => {
                  setYearExp(e.target.value);
                }}
                placeholder="Year Of experience"
              />
              <input
                type="text"
                name=""
                id=""
                value={aboutExp}
                required
                onChange={(e) => {
                  setAboutExp(e.target.value);
                }}
                placeholder="About experience"
              />

              <button type="submit">CONFIRM</button>
            </form>
          </div>
        </>
      ) : (
        ""
      )}

      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>My Learnings</p>
        </div>
        {/* <div className={style.HeaderIcons}>
              <FiPower/>
        </div> */}
        <div></div>
      </div>
      <div className={style.CategoryDiv}>
        <div className={style.Head}>
          {/* <p className={style.label}>Search here</p> */}
          <div className={style.Input}>
          <BiSearch className={style.Icons} />
            <input onFocus={(e) => {
              e.target.setAttribute("data-placeholder", e.target.placeholder);
              e.target.placeholder = "";
            }}
            onBlur={(e) => {
              e.target.placeholder = e.target.getAttribute("data-placeholder");
            }}  placeholder="Search here" onChange={(e)=>{
                setSearch(e.target.value)
            }}
            disabled={subCategory}
            />
          </div>
        </div>
  
        <div className={style.Content}>
          <div className={style.Heading}>
            <p>{currentPath}</p>
          </div>
          {category ? (
            <div className={style.Grid}>
              {details&&details.length > 0 ?
                details.map((learning) => {
                  return (
                    <div
                      className={style.card}
                      onClick={() => {
                        localStorage.setItem("MainLevelId", learning.category_id);
                        subLearning(learning.category_id);
                        setCategory(false);
                        setSubCategory(true);
                        handleCardClick(learning.Name)
                        setLoading(true)
                      }}
                    >
                      <p>{learning.Name}</p>
                      {/* <AiOutlineClose /> */}
                    </div>
                  );
                }):(
                  !search?"No data":
                  <div className={style.EmptyMessage}><p>No Matches Found</p></div>

                )}
            </div>
          ) : (
           ""
          )}
          {subCategory ? (
            <div className={style.Grid}>
              {subDetails.length > 0 &&
                subDetails.map((sublearn) => {
                  return (
                    <div
                      className={style.card}
                      onClick={() => {
                        localStorage.setItem("SubLevelId", sublearn.category_id);
                        handleCardClick(sublearn.Name)
                        
                        if (sublearn.next_data === 0) {
                          setPopup(true);
                          setGoal(true)
                          myGoal()
                        }
                        else{
                          subLearning(sublearn.category_id);
                          setLoading(true)
                        }
                      }}
                    >
                      <p>{sublearn.Name}</p>
                      {/* <AiOutlineClose /> */}
                    </div>
                  );
                })}
            </div>
          ) : (
            ""
          )}
          {popup ? (
            <div className={style.Overlay}>
                <div className={style.POPUP}>
                  {goal ? <div className={style.Goal}>
                      <div onClick={()=>{
                          setGoal(false)
                          setPopup(false)
                          LearningsGet()
                          setSubCategory(false)
                          setCurrentPath("Category")
                      }} className={style.Close}>
                              <AiOutlineClose/>
                      </div>
                      <div className={style.goalHead}>
                      {/* <p> We can help you in achieving your goals and that you level up your skills:</p> */}
                      <p> Set your goal for {currentPath}</p>
                    </div>
                    <div className={style.GoalDiv}>
                      <div className={style.goalInput}>
                        <p>Goal<span className={style.Required}>*</span></p>
                        <select
                          name=""
                          id=""
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            console.log("Selected goal:", selectedValue);
                            setGoalSelection(selectedValue);
                          }}
                        >
                          {" "}
                          <option value="">Select a goal</option>
                          {goalDetail.length > 0 &&
                            goalDetail.map((goal) => {
                              return (
                                <option key={goal.GoalID} value={goal.GoalName}>
                                  {goal.GoalName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className={style.goalInput}>
                        <p>Timeline<span className={style.Required}>*</span> </p>
                        <select
                          name=""
                          id=""
                          onChange={(e) => setTimeline(e.target.value)}
                        >
                          <option value="">Please Select a Month</option>
                          <option value="1">1 Month</option>
                          <option value="2">2 Month</option>
                          <option value="3">3 Month</option>
                          <option value="4">4 Month</option>
                          <option value="5">5 Month</option>
                          <option value="6">6 Month</option>
                          <option value="7">7 Month</option>
                          <option value="8">8 Month</option>
                          <option value="9">9 Month</option>
                          <option value="10">10 Month</option>
                          <option value="11">11 Month</option>
                          <option value="12">12 Month</option>
                        </select>
                      </div>
                      <div className={style.CommentInput}>
                        <p >Comment </p>
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="5"
                          onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                      </div>
                      {/* <div className={style.checkbox}>
                        <label className={style.mainLabel}>Please set your preferences of learning</label>
                        <div className={style.checkboxGroup1}>
                         <input type="checkbox" id="checkbox1" />
                         <label htmlFor="checkbox1">Need Individual Training</label><br/>
    
                           <input type="checkbox" id="checkbox2" />
                           <label htmlFor="checkbox2">Self Reading of Lessons</label><br/>
    
                          <input type="checkbox" id="checkbox3" />
                           <label htmlFor="checkbox3">Watching Training videos</label><br/>
    
                            <input type="checkbox" id="checkbox4" />
                           <label htmlFor="checkbox4">Offline Training</label><br/>
    
                         <input type="checkbox" id="checkbox5" />
                         <label htmlFor="checkbox5">Online Training</label><br/>
    
                         <input type="checkbox" id="checkbox6" />
                          <label htmlFor="checkbox6">Hybrid Training(Online&Offline)</label>
                         </div>
                      </div> */}
                      {/* <div className={style.checkbox}>
                      <label className={style.mainLabel}>Would you like to get individual training sessions ?</label>
                      <div className={style.checkboxGroup1}>
                      <input type="radio" id="checkbox1" />
                         <label htmlFor="checkbox1">Yes</label>
    
                           <input type="radio" id="checkbox2" />
                           <label htmlFor="checkbox2">No</label>
                      </div>
                      </div> */}
                      {/* <div className={style.checkbox}>
                      <label className={style.mainLabel}>Would you like to get more assessments ?</label>
                      <div className={style.checkboxGroup1}>
                      <input type="radio" id="checkbox1" />
                         <label htmlFor="checkbox1">Yes</label>
    
                           <input type="radio" id="checkbox2" />
                           <label htmlFor="checkbox2">No</label>
                      </div>
                      </div>
                      <div className={style.checkbox}>
                      <label className={style.mainLabel}>Would you like to practice the lessons ?</label>
                      <div className={style.checkboxGroup1}>
                      <input type="radio" id="checkbox1" />
                         <label htmlFor="checkbox1">Yes</label>
    
                           <input type="radio" id="checkbox2" />
                           <label htmlFor="checkbox2">No</label>
                      </div>
                      </div>
                      <div className={style.checkbox}>
                      <label className={style.mainLabel}>Do you want Peers (Other learners) with with you? </label>
                      <div className={style.checkboxGroup1}>
                      <input type="radio" id="checkbox1" />
                         <label htmlFor="checkbox1">Yes</label>
    
                           <input type="radio" id="checkbox2" />
                           <label htmlFor="checkbox2">No</label>
                      </div>
                      </div> */}
                      
                      <div className={style.goalButton}>
                        <button
                          onClick={(e) => {
                            Topic(e);
                            setGoal(false);
                            setTopic(true);
                          }}
                          disabled={
                            timeline===""||
                            goalSelection===""
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                  
                  : ""}
                  {topic?
                  <div className={style.topic}>
                      <div onClick={()=>{
                          setTopic(false)
                          setPopup(false)
                          LearningsGet()
                          setSubCategory(false)
                          setCurrentPath("Category")
                      }} className={style.Close}>
                              <AiOutlineClose/>
                      </div>
                  <div className={style.topicHead}>
                    <h4>Subject-{currentPath}</h4>
                  </div>
                  <div className={style.Topicdiv}>
                    <div className={style.topicDetails}>
                      {topicDetail.length > 0 &&
                        topicDetail[0].topics.map((topic) => {
                          return <p> {topic.value}</p>;
                        })}
                    </div>
                    <div className={style.learningButton}>
                      <button
                        onClick={() => {
                          SaveLearning();
                          // setTopic(false);
                          // setPopup(false)
                        }}
                      >
                        Start Learning
                      </button>
                    </div>
                  </div>
                </div>
                  :''
  
                  }
                </div>
            </div>
          ) : (
            ""
          )}
        </div>
  
      </div >    </div>
  );
};

export default Category;
