
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './Components/Login/Login';
import Register from './Components/Registration/Register'
import MyLearnings from './Components/MyLearnings/MyLearning'
import Home from './Components/Home/Home'
import Syllabus from './Components/Syllabus/Syllabus'
import Lesson from './Components/Lessons/Lesson'
import Layout from './Components/Layout';
import LearningDetail from './Components/LerningDetail/LearningDetail';
import TopicSingle from './Components/TopicSingle/TopicSingle';
import Category from './Components/Category/Category';
import Support from './Components/Support/Support';
import Message from './Components/Message/Message';
import Answer from './Components/AnswerSummary/Answer';
import Calendar from './Components/Calendar/Calendar';
import UserCalendar from './Components/MentorCalendar/Calendar'
import Search from './Components/Search/Search';
import Role from './Components/RoleSelecting/Role';
import Testing from './Components/Invitation/Invite';
import TransactionHistory from './Components/TransactionHistory/TransactionHistory';
import AccountValidation from './Components/AccountValidation/AccountValidation';
import RegistrationSuccess from './Components/RegistrationSuccess/RegistrationSuccess';
import ConnectionProfile from './Components/ConnectionProfile/ConnectionProfile';
import PasswordReset from './Components/PasswordReset/PasswordReset'
import Billing from './Components/Billing/Billing'
import Exams from './Components/Exams/Exams';
import ExamSummary from './Components/ExamSummary/ExamSummary';
import MobileLearning from './Components/MobileLearning/MobileLearning';
import MobileAssessment from './Components/MobileAssessment/MobileAssessment';
import MobileInsights from './Components/MobileInsights/MobileInsights';
import Quiz from './Components/Quiz/Quiz'
import QuizQuestions from './Components/QuizQuestions/QuizQuestions';
import Flip from './Components/Flip/Flip';
import Forum from './Components/Forum/Forum';
import Events from './Components/Events/Events';
import EventDetails from "./Components/EventDetails/EventDetails";

import Subscription from "./Components/Subscription/Subscription";
import SubscriptionSuccess  from "./Components/SubscriptionSuccess/Success";
import SubscriptionFail  from "./Components/SubscriptionFail/Fail";
import SubscriptionReady  from "./Components/Ready/Ready";
import Start  from "./Components/Start/Start";
import Layer from "./Layer";
import Dashboard from "./Components/Dashboard/Dashboard"
import Learnings from "./Components/Learnings/Learnings"
import NewLearningDetail from './Components/NewLearningDetail/NewLearningDetail';
import Assessment from './Components/Assessment/Assessment'
import ResetPassword from './Components/ResetPassword/ResetPassword';
import DailyDrill from './Components/DailyDrill/DailyDrill';
import WeeklyWorkout from './Components/WeeklyWorkout/WeeklyWorkout';
import Profile from './Components/NewProfile/Profile';
import EmailVerification from './Components/EmailVerification/EmailVerification';
import SubscriptionDetails from './Components/SubscriptionDetails/SubscriptionDetails';
import Performance from './Components/Performance/Performance';




function App() {
  return (
    <div className="App">

      <Router>
        <Routes>

        <Route element={<Layer />} >
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/subscribe" element={<Subscription />} />
            <Route path="/subscribe/success" element={<SubscriptionSuccess />} />
            <Route path="/subscribe/failed" element={<SubscriptionFail />} />
            <Route path="/subscribe/ready" element={<SubscriptionReady />} />
            <Route path="/start" element={<Start />} />
            <Route path="/reset/password" element={<ResetPassword />} />
            <Route path="/email/verification" element={<EmailVerification />} />
         </Route>

          
          {/* <Route path='/login' element={<Login />} /> */}
          {/* <Route path='/register' element={<Register />} /> */}
          <Route path='/search' element={<Search />} />
          <Route path='/roleselection' element={<Role />} />
          <Route path='/invite' element={<Testing />} />
          <Route path='/account/validation' element={<AccountValidation />} />
          <Route path='/registration/success' element={<RegistrationSuccess />} />
          <Route path='/resetpassword' element={<PasswordReset />} />
          {/* <Route path='/mobile/learning/65d44447a7b6b2398a9e1d7f/0202402191217201220135/65d44f4da7b6b2398a9e1d86/true' element={<MobileLearning/>}/> */}
          <Route path='/mobile/learning/:learningId/:currentTopicId/:userId/:adminStatus' element={<MobileLearning />} />
          <Route path='/mobile/assessment/:learningId/:currentTopicId/:userId/:adminStatus/:source' element={<MobileAssessment />} />
          {/* <Route path='/mobile/assessment/65dc5dd9c784d95706d187a8/020240226094214914388/65dc5c0ac784d95706d187a3/true/support' element={<MobileAssessment />} /> */}
          {/* <Route path='/mobile/insights/65d44447a7b6b2398a9e1d7f/0202402191217201220135/65d44f4da7b6b2398a9e1d86/true' element={<MobileInsights />} /> */}
          <Route path='/mobile/insights/:learningId/:currentTopicId/:userId/:adminStatus' element={<MobileInsights />} />
          <Route path='/flip' element={<Flip />} />
          <Route path='duno/events' element={<Events />} />
          <Route path='duno/forum' element={<Forum />} />




        </Routes>
        <Routes>
          <Route element={<Layout />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/learnings' element={<MyLearnings />} />
            <Route path='/syllabus' element={<Syllabus />} />
            <Route path='/lesson' element={<Lesson />} />
            <Route path='/lerning/detail' element={<LearningDetail />} />
            <Route path='/singletopic' element={<TopicSingle />} />
            <Route path='/category' element={<Category />} />
            <Route path='/support' element={<Support />} />
            <Route path='/message' element={<Message />} />
            <Route path='/answers' element={<Answer />} />
            <Route path='/calendar' element={<Calendar />} />
            <Route path='/usercalendar' element={<UserCalendar />} />
            <Route path='/transactionhistory' element={<TransactionHistory />} />
            <Route path='/peerprofile' element={<ConnectionProfile />} />
            <Route path='/billing' element={<Billing />} />
            <Route path='/exams' element={<Exams />} />
            <Route path='/exam/summary' element={<ExamSummary />} />
            <Route path='/quiz' element={<Quiz />} />
            <Route path='/quiz/questions' element={<QuizQuestions />} />
            <Route path='/forum' element={<Forum />} />
            <Route path='/events' element={<Events />} />
            <Route path='/event/details' element={<EventDetails />} />
            <Route path='/new/learnings' element={<Learnings />} />
            <Route path='/new/learnings/detail' element={<NewLearningDetail />} />
            <Route path='/assessments' element={<Assessment />} />
            <Route path='/daily/drill' element={<DailyDrill />} />
            <Route path='/weekly/workout' element={<WeeklyWorkout />} />
            <Route path="/subscribe/details" element={<SubscriptionDetails />} />
            <Route path="/performance" element={<Performance />} />
            

          </Route>
        </Routes>
      </Router>

    </div>
  );
}

export default App;
