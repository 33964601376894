import React,{useEffect} from "react";
import style from "./Success.module.css";
import SuccessImg from "../../assests/success .png";
import { useNavigate,useLocation } from "react-router-dom";
import { baseurl } from "../Url";


function Success() {
  const navigate = useNavigate();
  const location=useLocation()
  const searchParams = new URLSearchParams(location.search);
  const sessionid = searchParams.get('session_id');

 
 
   async function verifyPayment() {
     await fetch(`${baseurl}/verify-payment/stripe`, {
       method: "POST",
       body: JSON.stringify({
        checkout_session_id:sessionid
       }),
       headers: {
         "Content-Type": "application/json",
       },
     })
       .then((res) => res.json())
       .then((data) => {
         console.log(data);
         if (data.status === true) {
          localStorage.setItem(
            "access-token",
            JSON.stringify(data.data[0]?.access_token)
          );
          localStorage.setItem("ClubID", data.data[0]?.club_id);
          localStorage.setItem("ORGID", data.data[0]?.org_id);
          localStorage.setItem("USERID", data.data[0]?.user_id);
          localStorage.setItem("mentor", data.data[0]?.is_mentor);
          localStorage.setItem("username", data.data[0]?.username);
         }
       });
   }

   useEffect(()=>{
    verifyPayment()
   },[sessionid])

  return (
    <div className={style.Container}>
      <div className={style.successCard}>
       <div className={style.cardBody}>
            <div className={style.Cardheading}>
              <p>Your payment has been</p>
              <h3>Successful !</h3>
            </div>
    
            <div className={style.SuccessImg}>
              <img src={SuccessImg} alt="Success Img " />
            </div>
    
            <div className={style.msg}>
              <p> Thank you for subscribing to GrammarLocker. </p>
              <p> Your subscription is now active. </p>
            </div>
    
            <div className={style.welcome}>
              <p>Welcome Aboard ! </p>
    
              <button onClick={() => navigate("/subscribe/ready")}>Done</button>
            </div>
       </div>
      </div>
    </div>
  );
}

export default Success;
