import React, { useEffect, useState } from "react";
import style from "./LearningDetail.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FiPower } from "react-icons/fi";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BsFileBarGraph } from "react-icons/bs";
import Loader from "../Loder/Loader";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { baseurl } from "../Url";
import { PiExam } from "react-icons/pi";
import { BsBookHalf } from "react-icons/bs";
import { Doughnut } from "react-chartjs-2";
import { AnimationData } from "../../Animation/AnimationData";
import Lottie from "lottie-react";
import { IoPersonCircleOutline } from "react-icons/io5";
import goldMedal from "../../assests/goldnew-removebg-preview.png";
import silverMedal from "../../assests/silvercutnew-removebg-preview.png";
import brownsMedal from "../../assests/brownNew-removebg-preview.png";
import Whiteboard from "../WhiteBoard/WhiteBoard";
import { BiClipboard } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

const LearningDetail = () => {
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const Details = state.learning_detail;
  const peerRole = state?.peerRole;
  const learn_id = localStorage.getItem("newLearningId");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [topicDetail, setTopicDetail] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const learnertoken = localStorage.getItem("learnertoken");
  const [token, setToken] = useState(learnertoken ? learnertoken : key);
  const [popup, setPopup] = useState(false);
  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");
  const [graphData, setGraphData] = useState([]);
  const [assessmentData, setAssessmentData] = useState({});
  const learneruserid = localStorage.getItem("learneruserid");
  const [user_ID, setUser_ID] = useState(
    peerRole !== undefined ? learneruserid : USERID
  );
  const [cardListDetail, setCardListDetail] = useState([]);
  const [leaderBoardShow, setLeaderBoardShow] = useState(false);
  const [rewardValues, setRewardValues] = useState([]);
  const [mainTopicId, setMainTopicId] = useState("");
  const [clubId, setClubId] = useState("");
  // const [whiteBoradShow, setWhiteBoardShow] = useState(false);
  const [whiteBoradIconShow, setWhiteBoardIconShow] = useState(true);
  const [moduleList, setModuleList] = useState([]);

  const [subscriptionPopup, setSubscriptionPopup] = useState(false);

  async function rewardPoint() {
    await fetch(`${baseurl}/reward/dashboard`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        club_id: clubId,
        main_topic_id: mainTopicId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setRewardValues(data.data);
      });
  }

  // Function to fetch module list
  async function fetchModuleList() {
    try {
      const org_id = localStorage.getItem("ORGID");

      if (!org_id) {
        throw new Error("org_id not found in local storage");
      }

      const response = await fetch(`${baseurl}/module/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ org_id }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch module list");
      }

      const data = await response.json();
      setModuleList(data.data);
    } catch (error) {
      console.error("Error fetching module list:", error);
    }
  }

  // function doesModuleExist(moduleId) {
  //   return moduleList.some((mod) => mod.module_id === moduleId);
  // }

  function isModuleActive(moduleId) {
    const module = moduleList.find((mod) => mod.module_id === moduleId);
    return module && module.module_status === "active";
  }

  function isModuleFree(moduleId) {
    const module = moduleList.find((mod) => mod.module_id === moduleId);
    return module && module.module_price === "free";
  }

  function isModulePaid(moduleId) {
    const module = moduleList.find((mod) => mod.module_id === moduleId);
    return module && module.module_type === "paid";
  }

  function isModuleEnabled(moduleId) {
    const module = moduleList.find((mod) => mod.module_id === moduleId);
    return module && module.module_state === "enabled";
  }

  useEffect(() => {
    fetchModuleList();
  }, []);

  async function cardDetail() {
    await fetch(`${baseurl}/userlearning/details/individual`, {
      method: "POST",
      body: JSON.stringify({
        userlearning_id: learn_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("TEST DUNO LOG ", data);
        setTopicDetail(data.data);
        setLoading(false);
        setMainTopicId(data.data[0].Topics[0].topic_id);
      });
  }
  async function piChart() {
    await fetch(`${baseurl}/userlearning/details`, {
      method: "POST",
      body: JSON.stringify({ user_id: user_ID }),

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCardListDetail(data.data[0]);
        setClubId(data.data[0].club_id);
      });
  }
  async function barGraph(topic) {
    await fetch(`${baseurl}/individual/topic/analytics`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: user_ID,
        topic_id: topic,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (
          data &&
          data.data &&
          data.data[0] &&
          data.data[0].completion_percentage
        ) {
          setGraphData(data.data[0].completion_percentage);
          setLoading(false);
          setAssessmentData({
            Total_attempted_assessments:
              data.data[0].Total_attempted_assessments,
            Total_correct_answers: data.data[0].Total_correct_answers,
            Total_incorrect_answers: data.data[0].Total_incorrect_answers,
          });
        } else {
          console.error(
            "Completion percentage data not found in the API response."
          );
        }
      });
  }
  useEffect(() => {
    console.log(graphData);
  }, [graphData]);
  const data = {
    labels: graphData.map((item) => item.x),
    datasets: [
      {
        label: "Completion percentage",
        data: graphData.map((item) => item.y),
        backgroundColor: "#1a1e5d",
        barThickness: 50,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        max: 100,
        min: 0,
        stepSize: 10,
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  const [closing, setClosing] = useState(false);

  useEffect(() => {
    if (closing) {
      setTimeout(() => {
        setClosing(false);
      }, 1000);
    }
  }, [closing]);

  useEffect(() => {
    cardDetail();
    piChart();
  }, []);

  const handleCardClick = (moduleId, topic) => {
    if (isModulePaid(moduleId) && !isModuleFree(moduleId)) {
      setSubscriptionPopup(true);
    } else {
      const source = peerRole !== undefined ? "support" : "";
      if (topic.type === "exam") {
        navigate("/exams", {
          state: {
            exam_id: topic.id,
            examname: topic.value,
          },
        });
      } else {
        navigate("/singletopic", {
          state: {
            singleTopic: topic,
            learning: topicDetail[0].learning_id,
            mainid: module.topic_id,
            mapped: Details.admin_mapped_lessons,
            peerRole: peerRole,
            source,
          },
        });
      }
    }
  };

  return (
    <div className={style.container}>
      {loading ? <Loader /> : ""}

      {/* {whiteBoradIconShow ? (
        <div className={style.chatboticon}>
          <div
            onClick={() => {
              setWhiteBoardShow(true);
              setWhiteBoardIconShow(false);
            }}
            className={style.icontext}
          >
            <BiClipboard className={style.WhiteboardFloatingIcon} />
            <p> Whiteboard</p>
          </div>
        </div>
      ) : (
        ""
      )} */}
      {/* {whiteBoradShow ? (
        <div
          className={`${style.WhiteBoradContainer} ${
            closing ? style.closing : ""
          }`}
        >
          <div className={style.WhiteBoradHead}>
            <div className={style.HeadText}>White Board</div>
            <div
              className={style.WhiteBoradIcon}
              onClick={() => {
                setTimeout(() => {
                  setWhiteBoardIconShow(true);
                  setWhiteBoardShow(false);
                }, 500);
                setClosing(true);
              }}
            >
              <AiOutlineClose />
            </div>
          </div>
          <div className={style.WhiteBoradContent}>
            <Whiteboard />
          </div>
        </div>
      ) : (
        ""
      )} */}
      {popup ? (
        graphData && graphData.length > 0 ? (
          <div
            className={style.PopupOverlay}
            onClick={() => {
              setPopup(false);
              setGraphData([]);
              setAssessmentData({});
            }}
          >
            <div className={style.Popup}>
              <div className={style.AssessmentData}>
                <p>
                  Total Attempted Assessments:{" "}
                  {assessmentData.Total_attempted_assessments}
                </p>
                <p>
                  Total Correct Answers: {assessmentData.Total_correct_answers}
                </p>
                <p>
                  Total Incorrect Answers:{" "}
                  {assessmentData.Total_incorrect_answers}
                </p>
              </div>
              <Bar data={data} options={options} />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setPopup(false);
              setGraphData([]);
              setAssessmentData({});
            }}
            className={style.PopupOverlay}
          >
            <div className={style.Popup}>No data available</div>
          </div>
        )
      ) : (
        ""
      )}
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Lessons</p>
        </div>
      </div>
      <div className={style.LessonDiv}>
        <div className={style.Heading}>
          <p>{Details.category_details}</p>
        </div>
        <div className={style.Card}>
          <div className={style.CardContent}>
            <div className={style.First}>
              <div className={style.SeccondContent}>
                <div className={style.SeccondContentFirst}>Goal</div>
                <div className={style.SeccondContentSecond}>
                  : {Details.goal}
                </div>
              </div>
              <div className={style.SeccondContent}>
                <div className={style.SeccondContentFirst}>No.of Modules</div>
                <div className={style.SeccondContentSecond}>
                  :{" "}
                  <span style={{ color: "#902ded" }}>
                    {Details.Topics.length}
                  </span>
                </div>
              </div>
              <div className={style.SeccondContent}>
                <div className={style.SeccondContentFirst}>Assessment Done</div>
                <div className={style.SeccondContentSecond}>
                  :{" "}
                  <span style={{ color: "#902ded" }}>
                    {Details.assessment_done}/{Details.total_assessment}
                  </span>
                </div>
              </div>
            </div>
            <div className={style.Seccond}>
              <div className={style.SeccondContent}>
                <div className={style.SeccondContentFirst}>Timeline</div>
                <div className={style.SeccondContentSecond}>
                  : {Details.timeline} Month
                </div>
              </div>
              <div className={style.SeccondContent}>
                <div className={style.SeccondContentFirst}>Status</div>
                <div className={style.SeccondContentSecond}>
                  :{" "}
                  <span
                    style={{
                      color:
                        Details.Status === "Not Started"
                          ? "red"
                          : Details.Status === "In Progress"
                          ? "#f2781b"
                          : Details.Status === "Completed"
                          ? "green"
                          : "",
                    }}
                    className={style.Status}
                  >
                    {Details.Status}
                  </span>
                </div>
              </div>
            </div>
            <div className={style.roundGraph}>
              <Doughnut
                data={{
                  labels: ["Completed", "Remaining"],
                  datasets: [
                    {
                      data: [
                        cardListDetail.assessment_done,
                        cardListDetail.total_assessment -
                          cardListDetail.assessment_done,
                      ],
                      backgroundColor: ["#5cab27", "#e0e0e0"],
                    },
                  ],
                }}
              />
              <p>
                Completion:{" "}
                {cardListDetail.total_assessment !== 0
                  ? `${(
                      (cardListDetail.assessment_done /
                        cardListDetail.total_assessment) *
                      100
                    ).toFixed(2)}%`
                  : "0%"}
              </p>
              <div className={style.flashingButton}>
                <button
                  className={style.flashing}
                  onClick={(event) => {
                    event.stopPropagation();
                    setLeaderBoardShow(true);
                    rewardPoint();
                  }}
                >
                  Leader Board
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={style.Table}>
          <div className={style.learningCardContainer}>
            {topicDetail && topicDetail.length > 0 ? (
              topicDetail[0].Topics.map((module, moduleIndex) => {
                const moduleInList = moduleList.find(
                  (mod) => mod.module_id === module.module_id
                );
                const filteredTopics = module.topics.filter(
                  (topic) =>
                    topic.publish_status === "publish" ||
                    !topic.hasOwnProperty("publish_status")
                );

                return (
                  <div
                    className={style.cardMain}
                    id={module.module_id}
                    key={moduleIndex}
                    style={{
                      display: moduleInList
                        ? isModuleActive(module.module_id)
                          ? "block"
                          : "none"
                        : "block", // Display module if not present in the list
                      pointerEvents: moduleInList
                        ? isModuleEnabled(module.module_id)
                          ? "auto"
                          : "none"
                        : "auto", // Allow interactions if module not in the list
                      opacity: moduleInList
                        ? isModuleEnabled(module.module_id)
                          ? 1
                          : 0.5
                        : 1, // Full opacity if module not in the list
                      cursor: moduleInList
                        ? isModuleEnabled(module.module_id)
                          ? "default"
                          : "not-allowed"
                        : "default", // Default cursor if module not in the list
                    }}
                    onClick={() => {
                      localStorage.setItem("main_topic_id", module.topic_id);
                    }}
                  >
                    <div className={style.learningHead}>
                      <h2>{module.module_name}</h2>
                    </div>
                    {filteredTopics.map((topic, index) => (
                      <div
                        className={style.cardBox}
                        key={`${moduleIndex}-${index}`}
                      >
                        <div className={style.cardText}>
                          <span
                            className={style.Topiclink}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleCardClick(module.module_id, topic);
                              localStorage.setItem(
                                "main_topic_id",
                                module.topic_id
                              );
                            }}
                          >
                            {topic.type === "exam" ? (
                              <PiExam className={style.ExamICon} />
                            ) : (
                              <BsBookHalf className={style.ExamICon} />
                            )}
                            {topic.value}
                          </span>
                        </div>
                        <div className={style.statBox}>
                          <strong
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent triggering the parent onClick event
                              barGraph(topic.id);
                              setPopup(true);
                              setLoading(true);
                            }}
                          >
                            Analytics:
                          </strong>
                          <div
                            className={style.TopicIcons}
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent triggering the parent onClick event
                              barGraph(topic.id);
                              setPopup(true);
                              setLoading(true);
                            }}
                          >
                            <BsFileBarGraph className={style.iconStat} />
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))}
                  </div>
                );
              })
            ) : (
              <div>{!loading ? "No data" : ""}</div>
            )}

            {subscriptionPopup && (
              <>
                <div
                  className={style.PopupOverlay}
                  onClick={() => setSubscriptionPopup(false)}
                >
                  <div className={style.SubPopup}>
                    <div className={style.subPopTxt}>
                      <p>You need to subscribe to Access this Module !!</p>
                    </div>
                    <div className={style.subBtn}>
                      <button onClick={() => setSubscriptionPopup(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {leaderBoardShow ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setLeaderBoardShow(false);
            }}
          ></div>
          <div className={style.leaderBoard}>
            <div className={style.boardHeader}>
              <h3>LEADER BOARD</h3>
            </div>
            <div className={style.boardSection}>
              {rewardValues &&
                rewardValues.length > 0 &&
                rewardValues.map((value, i) => {
                  return (
                    <div className={style.boardValues} key={i}>
                      <div className={style.valueBadge}>
                        {i === 0 ? (
                          <img src={goldMedal} alt="" />
                        ) : i === 1 ? (
                          <img src={silverMedal} alt="" />
                        ) : i === 2 ? (
                          <img src={brownsMedal} alt="" />
                        ) : (
                          <p>{i + 1}</p>
                        )}
                      </div>
                      <div className={style.valueAvatar}>
                        <IoPersonCircleOutline />
                      </div>
                      <div className={style.playerNameStar}>
                        <p>{value.name}</p>
                      </div>
                      <div className={style.playerNameStar}>
                        <p>
                          Points: <span>{value.points}</span>
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default LearningDetail;
