import React,{useState,useMemo} from 'react'
import style from "./Performance.module.css"
import performanceImage from "../../assests/hand-drawn-neuroeducation-illustration 4.png"
import ActiveCalendar from "../../assests/activeCalendar.png";
import ActiveWorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



const Performance = () => {

  const sampleGraph = [
    { topic_no: 1, percentage: 75, mark: 12, lat_mark: 61 },
    { topic_no: 2, percentage: 55, mark: 9 , lat_mark: 37},
    { topic_no: 3, percentage: 89, mark: 14 , lat_mark: 46},
    { topic_no: 4, percentage: 45, mark: 8 , lat_mark: 11},
    { topic_no: 5, percentage: 25, mark: 5, lat_mark: 12 },
    { topic_no: 6, percentage: 45, mark: 8, lat_mark: 24 },
    { topic_no: 7, percentage: 25, mark: 5, lat_mark: 14 },
    { topic_no: 8, percentage: 0, mark: 0 , lat_mark: 0},
    { topic_no: 9, percentage: 0, mark: 0, lat_mark: 0 },
  ];
    const [selectedModule, setSelectedModule] = useState(0);
    const [selectedDate, setSelectedDate] = useState("W");

    const getColorBasedOnPercentage = (percentage) => {
      if (percentage > 75) return "#03CD09";  
      if (percentage > 50) return "#FFCB34";   
      if (percentage > 25) return "#FF8800";   
      return "#FF2443";                         
    };

    const { labels, data } = useMemo(() => {
      const today = dayjs();
      let labels = [];
      let data = Array(9).fill(0); // Assuming 9 topics
  
      if (selectedDate === "W") {
        labels = Array.from({ length: 7 }, (_, i) => (today.startOf('week').add(i, 'day').format('DD')));
  
        labels.forEach((_, i) => {
          data[i % 9] = Math.floor(Math.random() * 10); // Random attendance data
        });
      } else if (selectedDate === "M") {
        const daysInMonth = today.daysInMonth();
        labels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
  
        labels.forEach((_, i) => {
          data[i % 9] = Math.floor(Math.random() * 10); // Random attendance data
        });
      }
  
      return { labels, data };
    }, [selectedDate]);
  
    const chartData = {
      labels,
      datasets: [
        {
          label: 'Topic Attendance',
          data: data,
          backgroundColor: '#FF8800',
          borderWidth: 0,
          barPercentage: 0.2,
          borderRadius: 5,
        },
      ],
    };
   //second bar
    const topicLabels = sampleGraph.map(item => ` ${item.topic_no}`);
  const scoreData = sampleGraph.map(item => item.percentage);

  const learningSectionRightData = {
    labels: topicLabels,
    datasets: [
      {
        label: 'Topic Scores',
        data: scoreData,
        backgroundColor: '#FFBE00',
        borderWidth: 0,
        barPercentage: 0.5,
        categoryPercentage: 0.8,
        borderRadius: 5,
      },
    ],
  };

  const getLineChartData = () => {
    const today = dayjs();
    const labels = Array.from({ length: 7 }, (_, i) => today.startOf('week').add(i, 'day').format('DD'));
    const scores = Array.from({ length: 7 }, () => Math.floor(Math.random() * 100)); // Sample data for percentage scores
  
    return { labels, scores };
  };
  

  const { labels: lineLabels, scores: lineScores } = getLineChartData();
  const currentMonth = dayjs().format('MMM'); // Get current month abbreviation
  const lineChartData = {
    labels: lineLabels,
    datasets: [
      {
        label: 'Today Scores',
        data: lineScores,
        borderColor: '#595BDB',
        backgroundColor: 'rgba(89, 91, 219, 0.2)',
        fill: true,
        tension: 0.3, // Creates a wave-like effect
        borderWidth: 2,
        pointBackgroundColor: '#595BDB',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const lineChartSecondData = {
    labels: lineLabels,
    datasets: [
      {
        label: 'Weekly Scores',
        data: lineScores,
        borderColor: '#29bcd6',
        backgroundColor: '#7adef0',
        fill: true,
        tension: 0.3, // Creates a wave-like effect
        borderWidth: 2,
        pointBackgroundColor: '#29bcd6',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };



  return (
    <div className={style.container}>
        <div className={style.performance}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>Performance</p>
            <span>Take a look at your subscription plan.</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={performanceImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>

        <div className={style.assessmentSection}>
            <div className={style.dailyDrillCard}>
            <div className={style.assessmentCardLeft}>
                <div className={style.assessmentCardImage}>
                    <img src={ActiveCalendar} alt='dailyDrill'/>
                </div>
            </div>
            <div className={style.assessmentCardRight}>
                <p>You’ve completed </p>
                <h4>92</h4>
                <span>Daily drills </span>
            </div>
            </div>

            <div className={style.weeklyWorkOutCard}>
            <div className={style.assessmentCardLeft}>
                <div className={style.assessmentCardImage}>
                    <img src={ActiveWorkOut} alt='weeklyWorkout'/>
                </div>
            </div>
            <div className={style.assessmentCardRight}>
                <p>You’ve completed </p>
                <h4>12</h4>
                <span>Weekly Workouts </span>
            </div>
            </div>
        </div>

        <div className={style.moduleSection}>
            <div className={style.moduleHeder}>
                <p>Modules</p>
            </div>
            <div className={style.numberBarSection}>
                <div className={style.numberBar}>
                  {[...Array(7)].map((_, index) => (
                    <div
                      key={index}
                      className={
                        selectedModule === index
                          ? style.selectedNumber
                          : style.number
                      }
                      onClick={()=>{
                        setSelectedModule(index)
                      }}
                    >
                      {index + 1}
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.moduleSubHeder}>
                <p>Module progress: <span>Sentences</span></p>
            </div>
            <div className={style.progressBarSection}>
            <div
              className={style.progressBar}
              style={{
                
                backgroundColor: "#8E88903D",
                borderRadius: "2rem",
              }}
            >
              <div
                style={{
                  width: `${75}%`,
                  backgroundColor: "#595BDB",
                  height: "12px",
                  transition: "width 0.5s ease-in-out",
                  borderRadius: "1rem",
                }}
              ></div>
            </div>
            <div className={style.progressBarCount}>
            <p>75%</p>
            </div>
          </div>
            <div className={style.roundGraphSection}>
              {sampleGraph.length>0&&sampleGraph.map((data,i)=>{
                const color = getColorBasedOnPercentage(data.percentage);
                const chartData = {
                  labels: ["Achieved", "Remaining"],
                  datasets: [
                    {
                      data: [data.percentage, 100 - data.percentage], // Fill based on percentage
                      backgroundColor: [color, "#EAEAEA"],   
                      borderWidth: 0,
                    },
                  ],
                };
      
                const options = {
                  cutout: '80%', 
                  plugins: {
                    legend: {
                      display: false, 
                    },
                    tooltip: {
                      enabled: false, 
                    },
                  },
                };
                return(
                <div key={i} className={style.roundGraphCard}>
                <div className={style.roundGraphCardHeder}>
                  <p>Topic {data.topic_no}</p>
                </div>
                <div className={style.roundGraph}>
                <Doughnut data={chartData} options={options} />
                <div className={style.roundGraphData}>
                  <p>{data.mark}</p>
                </div>
                </div>
  
                <div className={style.roundGraphCardBottom}>
                  <p>{data.percentage}%</p>
                </div>
                </div>
                )
              })}
            </div>
        </div>
        <div className={style.learningSection}>
        <div className={style.learningSectionLeft}>
          <div className={style.learningHeader}>
            <p>Learning Pace</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${selectedDate==="W"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("W")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="M"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("M")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="Y"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("Y")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
           <select>
            <option selected>Module 1</option>
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `Topic ${context.dataIndex + 1}: ${context.raw}`;
                },
              },
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: `Day (${dayjs().format('MMM')})`,
              },
              grid: {
                display: false, // Remove grid lines from x-axis
              },
              beginAtZero: false,
              ticks: {
                stepSize: 1,
              },
            },
            y: {
              title: {
                display: true,
                text: 'Topics',
              },
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                max: 9,
              },
            },
          },
        }}
      />
          </div>
        </div>
        <div className={style.learningSectionRight}>
        <div className={style.learningHeader}>
            <p>Learning Level</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.levelHeader}>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Current Score</h4>
            <p>(The mark scored in the last attempt)
            </p>
          </div>
          <div className={style.levelHeaderCard}>
            <span></span>
            <h4>Baseline score</h4>
            <p>(The mark scored in the first attempt)
            </p>
          </div>
          </div>
           <div className={style.learningSubHeaderRight}>
           <select>
            <option selected>Module 1</option>
           </select>
           </div>
          </div>
          <div className={style.barGraph}>
          <Bar
                data={learningSectionRightData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      callbacks: {
                        label: function (context) {
                          return `Topic ${context.dataIndex + 1}: ${context.raw}%`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: 'Topics',
                      },
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      title: {
                        display: true,
                        text: 'Score (%)',
                      },
                      beginAtZero: true,
                      ticks: {
                        stepSize: 20, 
                      },
                    },
                  },
                }}
              />
    
          </div>
        </div>
        </div>

        <div className={style.assessmentSection}>
        <div className={style.assessmentSectionLeft}>
        <div className={style.learningHeader}>
            <p>Daily drill</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${selectedDate==="W"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("W")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="M"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("M")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="Y"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("Y")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore}>
              <p>Today’s <br/>
              Score</p>
              <h4>12</h4>
            </div>
           </div>
          </div>
          <div className={style.lineGraph}>
          <Line
              data={lineChartData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return `${context.dataset.label}: ${context.raw}%`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: `Day (${currentMonth})`, // Dynamic month abbreviation
                    },
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={style.assessmentSectionRight}>
        <div className={style.learningHeader}>
            <p>Weekly Workout</p>
          </div>
          <div className={style.learningSubHeader}>
          <div className={style.learningSubHeaderLeft}>
           <div className={`${style.subHeaderCard} ${selectedDate==="W"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("W")}>
            <p>W</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="M"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("M")}>
            <p>M</p>
           </div>
           <div className={`${style.subHeaderCard} ${selectedDate==="Y"?style.selectedSubHeaderCard:""}`}
           onClick={() => setSelectedDate("Y")}>
            <p>Y</p>
           </div>
           </div>
           <div className={style.learningSubHeaderRight}>
            <div className={style.assessmentScore} style={{backgroundColor:"#00DFC7"}}>
              <p>Week's <br/>
              Score</p>
              <h4>12</h4>
            </div>
           </div>
          </div>

          <div className={style.lineGraph}>
          <Line
              data={lineChartSecondData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return `${context.dataset.label}: ${context.raw}%`;
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: `Day (${currentMonth})`, // Dynamic month abbreviation
                    },
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    title: {
                      display: true,
                      text: 'Score (%)',
                    },
                    grid: {
                      display: false,
                    },
                    beginAtZero: true,
                    ticks: {
                      stepSize: 20,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        </div>

        </div>
    </div>
  )
}

export default Performance