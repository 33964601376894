import React from "react";
import style from "./HeadBar.module.css";
import EnglishWagonLogo from "../../assests/English wagon logo_Actual 1.png";



function HeadBar() {
  return (
    <div className={style.Container}>
      <div className={style.topBar}>
        <div className={style.topImg}>
          <img src={EnglishWagonLogo} alt="English Wagon Logo" />
        </div>

        <div className={style.topBarMenu}>
          <p>About</p>
          <p>How it works</p>
          <p>Contact</p>
        </div>
      </div>
    </div>
  );
}

export default HeadBar;
