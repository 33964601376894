import React,{useState} from 'react'
import { Outlet } from 'react-router-dom';
import Cookie from './Cookie/Cookie';
import InnerHeadBar from './InnerHeadBar/InnerHeadBar'
import Sidebar from './NewSidebar/Sidebar'
const Layout = () => {
  const layoutContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const mainContentStyle = {
    display: 'flex',
    flex: 1,
  };

  const contentStyle = {
    flex: 1,
  };

  const [finishPopup,setFinishPopup]=useState(false);
  const [finished, setFinished] = useState(false); 
  const [timeRunning, setTimeRunning] = useState(false);


  return (
    <div style={layoutContainerStyle}>
        <InnerHeadBar />
      <div style={mainContentStyle}>
          <Sidebar 
          finished={finished} 
          timeRunning={timeRunning} 
          setFinishPopup={setFinishPopup} />
        <div style={contentStyle}>
          <Outlet context={{ finishPopup, setFinishPopup,setTimeRunning,finished,setFinished ,timeRunning}}/>
        </div>
      </div>
        <Cookie />
    </div>
  )
}

export default Layout